<template>
  <div class="my-upload" :style="{'height': `${height}px`,'width': `${width}px`}">
    <el-upload
      class="custom-upload"
      :class=" `bg${type}`"
      :accept="accept.toString()"
      :limit="1"
      :action="uploadUrl"
      :before-upload="fileUpload"
      :on-remove="removeFile"
      v-if="!url"
      v-loading="uploading"
    >
      <div >
        <i class="el-icon-plus avatar-uploader-icon"></i>
      </div>
    </el-upload>
    <div class="detail" v-else>
      <img
        v-if="url"
        :src="fileBaseUrl + url"
        class="avatar"
      />
    </div>
    <p class="name">{{name}} <span @click="removeFile" v-if="url && !disabled">修改</span></p>
  </div>
</template>

<script>
import url from '@/api/url/hy_url'
const { baseUrl } = require('@/config')
import { reqUploadfile, discernUploadfile, ocrIdcardUploadfile } from "@/api/hy";
export default {
  data() {
    return {
      uploadUrl: baseUrl + url.upLoadUrl,
      fileBaseUrl: baseUrl + url.getFileUrl,
      url: '',//'20211117062154732-68-.jpg',
      accept: ['.bmp','.png','.jpg','.jpeg'],
      uploading: false
    };
  },
  mounted() {
  },
  props:{
    validate: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 360
    },
    height: {
      type: Number,
      default: 240
    },
    type: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value (val) {
      this.url = val
    }
  },
  methods: {
    fileUpload (file) {
      let type = this.accept.map(item => item.split('.')[1])
      let reg = new RegExp(`^.+\.(${type.join('|')})$`, 'i')
      if (!reg.test(file.name)) {
        this.$message({
          message: `请上传${type.join('、')}格式的图片`,
          type: 'error'
        });
        return false
      }
      let maxSize = 10
      let size = file.size / 1024 / 1024 < maxSize
      if (!size) {
        this.$message.error(`上传图片大小不能超过 ${maxSize}MB!`)
        return false
      }
      this.uploading = true
      
      this.discernUploadfile(file)
      reqUploadfile(file).then((ret)=>{
        if (ret.code == 0) {
          this.url = ret.data
          this.uploading = false
          this.$emit('input', this.url)
          console.log(this.$parent.$parent);
          this.$parent.$parent.validateField([this.validate])
        }
      }).catch((err)=>{
        this.uploading = false
      })
      return false
    },
    removeFile () {
      this.url = ''
      this.$emit('input', this.url)
    },
    discernUploadfile(file){
      if (this.type=="3") {
        discernUploadfile(file).then(res=>{
          if (res.code == 0) {
            this.$emit('tradingCertificate',res.data)
          } else {
            this.$message.error('营业执照解析失败,请手动输入')
          }
        })
        .catch(e=>{
          this.$message.error('营业执照解析失败,请手动输入')
        })
      }else if (this.type=="2"){
        ocrIdcardUploadfile(file).then(res=>{
          if (res.code == 0) {
            this.$emit('ocrIdcardCertificate',res.data)
          } else {
            this.$message.error('身份证解析失败,请手动输入')
          }
        })
        .catch(e=>{
          this.$message.error('身份证解析失败,请手动输入')
        })
      }else {
        ocrIdcardUploadfile(file).then(res=>{
          if (res.code == 0) {
            this.$emit('idcardCertificate',res.data)
          } else {
            this.$message.error('身份证解析失败,请手动输入')
          }
        })
        .catch(e=>{
          this.$message.error('身份证解析失败,请手动输入')
        })
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.my-upload{
  position: relative;
  margin-bottom: 30px;
  // border: 2px dashed #cecece;
}
.custom-upload{
  text-align: center;
  height: 100%;
  position: relative;
  .el-upload{
    width: 100%;
    height: 100%;
  }
  div{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    span{
      line-height: 1.5em
    }
  }
}
::v-deep{
  .avatar-uploader-icon{
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    background: #CC9933;
    opacity: 0.5;
    color: white;
    font-size: 36px;
    border-radius: 50%;
  }
}
.bg1{
  background: url('../../../../assets/img/id001.jpg') no-repeat;
  background-size: 100% 100%;
}
.bg2{
  background: url('../../../../assets/img/id002.jpg') no-repeat;
  background-size: 100% 100%;
}
.bg3{
  background: url('../../../../assets/img/id003.jpg') no-repeat;
  background-size: 100% 100%;
}
.detail{
  width: 100%;
  height: 100%;
  text-align: center;
  border: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    display: inline-block;
  }
  .remove{
    display: none;
  }
  // &:hover .remove{
  //   display: inline-block;
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   background: rgba(0,0,0,0.2);
  //   i{
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     transform: translate(-50%,-50%);
  //     cursor: pointer;
  //   }
  // }
}
.name{
  text-align: center;
  span{
    cursor: pointer;
    color: #0294FF;
  }
}
</style>